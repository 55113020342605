import { useGetFeeSettingsQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useFetchFeeSettings = () => {
  const { data } = useGetFeeSettingsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      page: 1,
      perPage: 100, // 全件取得したいので大きめ数字を設定しておく
    },
  });

  const feeSettings = data ? getMe(data)?.organization.feeSettings.nodes || [] : [];

  return feeSettings;
};
