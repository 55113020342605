import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { BASE_DATE } from '~/constants/organization_notification';
import {
  AppointmentStatus,
  ChargeWithApp2Input,
  ChargeWithApp2LineItemInput,
  ChargeWithCash2Input,
  GetUnreadAppointmentsNotificationsDocument,
  LineItemCode,
  PlanFeatureCode,
  useChargeWithAppMutation,
  useChargeWithCashMutation,
  useSkipEncounterMutation,
  useUpdateDeliveryMethodMutation,
  WebAppointmentForChargeFragment,
} from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { followupMessageSettingModalState } from '~/state/partials/followup_message_setting_modal/atoms';

import { Fields } from './types';

export const useCharge = () => {
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);
  const [error, setError] = useState<string | null>(null);

  const [skipEncounter, { loading: skipping }] = useSkipEncounterMutation({
    // ネット受付の場合skipEncounter -> chargeと連続してリクエストする
    // その際にskipEncounterのレスポンスでキャッシュ更新走ってリストの再取得など走ると不都合がおきるのでno-cacheを指定した
    fetchPolicy: 'no-cache',
  });
  const [updateDeliveryMethod, { loading: changing }] = useUpdateDeliveryMethodMutation();
  const [chargeWithApp, { loading: chargingWithApp }] = useChargeWithAppMutation({
    refetchQueries: [
      {
        query: GetUnreadAppointmentsNotificationsDocument,
        variables: { from: BASE_DATE },
      },
    ],
  });
  const [chargeWithCash, { loading: chargingWithCash }] = useChargeWithCashMutation({
    refetchQueries: [
      {
        query: GetUnreadAppointmentsNotificationsDocument,
        variables: { from: BASE_DATE },
      },
    ],
  });
  // 薬歴連係で使用しているので、共通のstateを使用する
  const setModal = useSetRecoilState(followupMessageSettingModalState);

  const charge = useCallback(
    async (appointment: WebAppointmentForChargeFragment, values: Fields) => {
      const input: ChargeWithApp2Input | ChargeWithCash2Input = {
        appointmentId: appointment.id,
        lineItems: [values.lineItem, ...values.optionalLineItems].map<ChargeWithApp2LineItemInput>(
          (lineItem) => ({
            code: lineItem.code as LineItemCode,
            subject: lineItem.subject,
            amount: +lineItem.amount,
            chargeItems: [],
          }),
        ),
      };

      setError(null);

      if (!values.deliveryMethod) {
        const errorMessage = '配送方法が選択されていません';
        setError(errorMessage);
        throw new Error(errorMessage);
      }

      try {
        if (!appointment.telemedicine && appointment.status === AppointmentStatus.Booked) {
          await skipEncounter({
            variables: {
              input: {
                appointmentId: appointment.id,
              },
            },
          });
        }

        await updateDeliveryMethod({
          variables: {
            input: {
              appointmentId: appointment.id,
              deliveryMethod: values.deliveryMethod,
            },
          },
        });

        if (values.paymentMethod === 'app') {
          await chargeWithApp({ variables: { input } });
        } else if (values.paymentMethod === 'cash') {
          await chargeWithCash({ variables: { input } });
        } else {
          // おかしい
        }
      } catch (err) {
        const errorMessage = (err && err.message) || 'エラーが発生しました';
        setError(errorMessage);
        throw new Error(errorMessage);
      }

      if (hasEnabledFollowup) {
        setModal((_state) => ({
          ..._state,
          isOpen: true,
          guestPatientId: appointment.webVisitor?.id || null,
        }));
      }
    },
    [
      chargeWithApp,
      chargeWithCash,
      hasEnabledFollowup,
      setModal,
      skipEncounter,
      updateDeliveryMethod,
    ],
  );

  return {
    error,
    charging: chargingWithCash || chargingWithApp || skipping || changing,
    charge,
  };
};
