import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';

import { Box, EntryList, FieldError, Select } from '~/components/blocks';
import { DeliveryMethodLabel } from '~/constants/delivery_method';
import { AppointmentDeliveryMethod } from '~/graphql';

type Props = {
  value: AppointmentDeliveryMethod | '';
  onChange: (value: AppointmentDeliveryMethod | '') => void;
};

const OPTION_KYES = [
  'b2cloud_cash_on_delivery',
  'b2cloud_collect',
  'b2cloud_compact',
  'b2cloud_compact_collect',
  'b2cloud_dm',
  'b2cloud_nekopos',
  'b2cloud_prepayment',
  'b2cloud_time',
  'hand',
  'other',
] as const;

export const DeliveryMethodField = (props: Props) => {
  const { onChange } = props;
  const theme = useTheme();
  const handleChangeCode = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const deliveryMethod = e.currentTarget.value;
      onChange(deliveryMethod as AppointmentDeliveryMethod);
    },
    [onChange],
  );
  return (
    <Box
      bg={theme.colors.background.bg}
      marginTop={theme.space.m}
      p={`${theme.space.m} ${theme.space.l}`}
    >
      <EntryList>
        <EntryList.Head>配送方法</EntryList.Head>
        <EntryList.Body>
          <Select fill value={props.value || ''} onChange={handleChangeCode}>
            <option hidden value="">
              選択してください
            </option>
            {OPTION_KYES.map((key) => (
              <option key={key} value={key}>
                {DeliveryMethodLabel[key]}
              </option>
            ))}
          </Select>
          {!props.value && <FieldError error="配送方法を選択してください" />}
        </EntryList.Body>
      </EntryList>
    </Box>
  );
};
