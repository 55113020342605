import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Alert, Box, Button, EntryList, Icon, Modal, Text } from '~/components/blocks';
import { SimpleWebVisitorProfile, TotalAmount } from '~/components/partials';
import { WebAppointmentForChargeFragment } from '~/graphql';
import { webVisitorChargeModalState } from '~/state/reception/atoms';
import { Label } from '~/utils/label';

import { Fields } from './types';
import { useCharge } from './use-charge';

type Props = {
  needsDeliveryMethod: boolean;
  appointment: WebAppointmentForChargeFragment;
  values: Fields;
  onBack: () => void;
};

const AmountItem = styled('dl')(
  ({ theme }) =>
    () =>
      css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.space.m,
        '& + &': {
          borderTop: theme.borders.default,
        },
        '& > dt': {},
        '& > dd': {
          textAlign: 'right',
        },
      }),
);

export const ConfirmPanel = React.memo((props: Props) => {
  const { onBack } = props;
  const theme = useTheme();
  const resetState = useResetRecoilState(webVisitorChargeModalState);
  const { charge, charging, error } = useCharge();
  const handleCharge = useCallback(async () => {
    try {
      await charge(props.appointment, props.values);
      resetState();
    } catch {
      // useChargeのerrorを使うのでここでは何もしない
    }
  }, [charge, props.appointment, props.values, resetState]);

  return (
    <>
      <Modal.Body>
        {error && (
          <Alert status="error" mb={theme.space.l}>
            {error}
          </Alert>
        )}
        <SimpleWebVisitorProfile visitor={props.appointment.webVisitor} />
        <Alert status="info" marginTop={theme.space.l}>
          <Box fontWeight={theme.fontWeights.bold} fontSize={theme.fontSizes.s}>
            請求内容を確認の上、 「確定」ボタンを押してください
          </Box>
        </Alert>
        <EntryList marginTop={theme.space.l}>
          <EntryList.Head>支払い方法</EntryList.Head>
          <EntryList.Body>
            <Text size="l" fontWeight={theme.fontWeights.bold}>
              窓口決済
            </Text>
          </EntryList.Body>
        </EntryList>
        <EntryList mt={theme.space.m}>
          <EntryList.Body>
            <Box bg={theme.colors.background.bg} p={theme.space.m}>
              <AmountItem>
                <dl>{props.values.lineItem.subject}</dl>
                <dd>{Label.amount(+props.values.lineItem.amount || 0)}</dd>
              </AmountItem>
              {props.values.optionalLineItems.map((lineItem, idx) => (
                <AmountItem key={idx}>
                  <dl>{lineItem.subject}</dl>
                  <dd>{Label.amount(+lineItem.amount || 0)}</dd>
                </AmountItem>
              ))}
            </Box>
            <TotalAmount lineItems={[props.values.lineItem, ...props.values.optionalLineItems]} />
          </EntryList.Body>
        </EntryList>
      </Modal.Body>

      <Modal.Footer both>
        <Button use="white" disabled={charging} onClick={onBack}>
          <Icon icon="back-line" size="m" /> 修正
        </Button>
        <Button use="secondary" loading={charging} onClick={handleCharge}>
          確定
        </Button>
      </Modal.Footer>
    </>
  );
});

ConfirmPanel.displayName = 'ConfirmPanel';
